<template>
  <div>
    <div v-if="!hideLetters" class="flex justify-between">
      <a
        v-for="group in groups"
        :key="'link_' + group.letter"
        :href="'#glossary-' + group.letter"
        class="link flex size-40 items-center justify-center rounded-full font-condensed text-base hover:bg-gray-100 lg:text-xl"
      >
        {{ group.letter }}
      </a>
    </div>
    <div
      v-for="group in groups"
      :id="'glossary-' + group.letter"
      :key="group.letter"
      class="mt-30 lg:mt-56 lg:flex"
    >
      <h3 class="typo-h2 font-condensed lg:-ml-40 lg:w-40 lg:text-right">
        <div class="lg:sticky lg:top-0 lg:pr-16">
          {{ group.letter }}
        </div>
      </h3>
      <ul class="flex-1" :class="wrapperClass">
        <li v-for="item in group.items" :key="item.id">
          <slot :item="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
interface Groupable {
  id?: string
}
</script>

<script setup lang="ts" generic="T extends Groupable">
const props = defineProps<{
  items: T[]
  groupField: keyof T
  sortField?: keyof T
  hideLetters?: boolean
  wrapperClass?: string
}>()

type Group = {
  letter: string
  items: T[]
}

function getGroupKey(text?: string | undefined | null): string {
  if (!text || typeof text !== 'string') {
    return '#'
  }
  const letter = text.toUpperCase().charAt(0)
  const code = letter.codePointAt(0) || 0
  return code >= 65 && code <= 90 ? letter : '#'
}

const groups = computed(() => {
  return Object.values(
    props.items.reduce<Record<string, Group>>((acc, v) => {
      const value = v[props.groupField]
      const letter = getGroupKey(value as any)

      if (!acc[letter]) {
        acc[letter] = {
          letter,
          items: [],
        }
      }

      acc[letter].items.push(v)

      return acc
    }, {}),
  )
    .map((group) => {
      const sortField = props.sortField
      if (!sortField) {
        return group
      }
      return {
        ...group,
        items: group.items.sort((a, b) => {
          const va = a[sortField]
          const vb = b[sortField]
          if (typeof va === 'string' && typeof vb === 'string') {
            return va.localeCompare(vb)
          }

          return 0
        }),
      }
    })
    .sort((a, b) => a.letter.localeCompare(b.letter))
})
</script>
